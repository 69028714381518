.thongtinchitiet {
  &__modal {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    z-index: 50;

    &--container {
      max-width: 500px;
      max-height: 500px;
      position: relative;
    }

    h2 {
      margin-top: 20px;
      font-weight: 700;
      font-size: 30px;
      line-height: 42px;
      font-family: 'Lora';
      text-align: center;
    }

    img {
      width: 100%;
      height: 350px;
    }

    button {
      position: absolute;
      top: -18px;
      right: -14px;
      color: white;
    }
  }

  &__header {
    align-items: center;

    h2 {
      font-family: "Roboto-Regular";
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 27px;
      color: #0068b9;
    }

    &--border {
      border-bottom: 1px solid #002a53;
    }
  }

  &__breadcrumbs span {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #002a53;
    margin-left: 5px;
    margin-right: 5px;
  }

  &__search {
    margin: 0 auto;
    width: 500px;
  }

  &__content {
    &--right {
      h3 {
        font-family: "Lora";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 52px;
        text-transform: uppercase;
        color: #fff;
      }
    }

    &--border {
      border-bottom: 1px solid #002a53;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &--text {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: #434343;
      margin-bottom: 20px;
    }

    &--left .mySwiper2 {
      height: 380px !important;
      margin-bottom: 15px;
    }

    &--left .mySwiper {
      height: 70px;
    }
  }

  &__bottom p {
    border-radius: 5px;
    border: 1px solid #000000;
  }
}

@media screen and (max-width: 768px) {
  .thongtinchitiet {
    &__content {
      &--right h3 {
        font-size: 30px;
        line-height: 30px;
      }

      &--btn {
        display: inline-flex;

        button {

          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .thongtinchitiet {
    padding-left: 25px;
    padding-right: 25px;

    &__content {
      display: inline-block;

      &--right {
        h3 {
          font-size: 24px;
        }

        margin-top: 30px;
        margin-left: 0;
      }
    }

    &__bottom {
      p {
        border-radius: 0;
        border: 0;
        padding: 0;
      }

      h2 {
        margin-top: 30px;
      }
    }

    &__modal {
      &--container{
        width: 280px;
      }
    }
  }
}