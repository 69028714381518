.trangtracuucovat {
  min-height: calc(100vh - 200px);
  .pagination{
    display: flex;
    gap: 16px;
    justify-content: center;
    padding-bottom: 30px;
    li {
      background-color: #fff;
      border-radius: 4px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
    }
    .active{
      background-color: #F15922;
      color: #fff;
    }
  }
  &__header {
    align-items: center;

    h2 {
      font-family: 'Roboto-Regular';
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 27px;
      color: #0068B9;
    }
  }

  &__breadcrumbs span{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #002A53;
    margin-left: 5px;
    margin-right: 5px;
  }

  &__search {
    margin: 0 auto;
    width: 500px;
    border-bottom: 1px solid #002A53;
    max-width: 310px;
    width: 100%;

    input {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color: #002A53;
    }
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .trangtracuucovat__product {
    .listitem__product {
      padding-left: 20px;
      div {
        height: 140px;
      }
      h3 {
        font-size: 18px;
        margin-bottom: 8px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 675px) {
  .trangtracuucovat {
    padding-left: 70px;
    padding-right: 70px;
    &__search {
      width: 100%;
    }
  }
  .thongtinchitiet__header--border{
    margin-bottom: 30px;
  }
}