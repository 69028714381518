.footer {
  background: #EC5721;
  font-family: 'Roboto-Black';
  &__content {
    &--img{
      width: 62px;
      height: 60px;
    }
    &--text {
      font-weight: 500;
      font-family: 'Inter';
      font-size: 13px;
      line-height: 24px;
      color: #fff;
      display: flex;
      align-items: center;
    }
  }
  &__bottom {
    background-color: #F16937;
    padding-top: 3px;
    text-align: center;
    &--text{
      text-align: center;
      color: #fff;
      font-weight: 500;
      font-family: 'Inter';
      font-size: 13px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 960px) {
  .footer {
    &__content {
      display: block;
      padding-top: 20px;
      &--left{
        display: block;
        margin: 0;
        img {
          margin: 0 auto;
        }
      }
      h2 {
        margin: 0;
        text-align: center;
        margin-top: 14px;
      }
      &--text {
        span {
          margin-right: 16px;
          margin-left: 20px;
        }
      }
      &--right {
        width: 100%;
      }
    }
  }
}