@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icons/icomoon.eot?4s9a0l');
  src:  url('../../fonts/icons/icomoon.eot?4s9a0l#iefix') format('embedded-opentype'),
    url('../../fonts/icons/icomoon.ttf?4s9a0l') format('truetype'),
    url('../../fonts/icons/icomoon.woff?4s9a0l') format('woff'),
    url('../../fonts/icons/icomoon.svg?4s9a0l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-qr-code:before {
  content: "\e907";
}
.icon-share:before {
  content: "\e906";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-location:before {
  content: "\e900";
}
.icon-phone:before {
  content: "\e901";
}
.icon-email:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e904";
}
