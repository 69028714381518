@import './icons';
$urlFont: '../fonts';

@font-face {
  font-family: 'Roboto-Medium';
  src: url(../../fonts/Roboto-Medium.eot);
  src: url(../../fonts/Roboto-Medium.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Roboto-Medium.woff2) format('woff2'),
    url(../../fonts/Roboto-Medium.woff) format('woff'),
    url(../../fonts/Roboto-Medium.ttf) format('truetype'),
    url(../../fonts/Roboto-Medium.svg#Roboto-Medium) format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url(../../fonts/Roboto-Regular.eot);
  src: url(../../fonts/Roboto-Regular.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Roboto-Regular.woff2) format('woff2'),
    url(../../fonts/Roboto-Regular.woff) format('woff'),
    url(../../fonts/Roboto-Regular.ttf) format('truetype'),
    url(../../fonts/Roboto-Regular.svg#Roboto-Regular) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url(../../fonts/Roboto-Light.eot);
  src: url(../../fonts/Roboto-Light.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Roboto-Light.woff2) format('woff2'),
    url(../../fonts/Roboto-Light.woff) format('woff'),
    url(../../fonts/Roboto-Light.ttf) format('truetype'),
    url(../../fonts/Roboto-Light.svg#Roboto-Light) format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url(../../fonts/Roboto-Black.eot);
  src: url(../../fonts/Roboto-Black.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Roboto-Black.woff2) format('woff2'),
    url(../../fonts/Roboto-Black.woff) format('woff'),
    url(../../fonts/Roboto-Black.ttf) format('truetype'),
    url(../../fonts/Roboto-Black.svg#Roboto-Black) format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url(../../fonts/Roboto-Thin.eot);
  src: url(../../fonts/Roboto-Thin.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Roboto-Thin.woff2) format('woff2'),
    url(../../fonts/Roboto-Thin.woff) format('woff'),
    url(../../fonts/Roboto-Thin.ttf) format('truetype'),
    url(../../fonts/Roboto-Thin.svg#Roboto-Thin) format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(../../fonts/Roboto-Bold.eot);
  src: url(../../fonts/Roboto-Bold.eot?#iefix) format('embedded-opentype'),
    url(../../fonts/Roboto-Bold.woff2) format('woff2'),
    url(../../fonts/Roboto-Bold.woff) format('woff'),
    url(../../fonts/Roboto-Bold.ttf) format('truetype'),
    url(../../fonts/Roboto-Bold.svg#Roboto-Bold) format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url(../../fonts/Lora-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}


.font-regular {
  font-family: "Roboto-Regular";
}

.font-bold {
  font-family: "Roboto-Bold";
}

.font-medium {
  font-family: "Roboto-Medium";
}