.header {
  background-image: url('../../images/bg-header.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__content {
    font-family: 'Roboto-Light';

    h1 {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 600;
      font-size: 50px;
      line-height: 52px;
    }

    &--text {
      margin-left: 90px;
      margin-right: 90px;
      font-size: 20px;
      line-height: 30px;
    }

    &--img {
      display: block;
      margin: 0 auto;
      margin-bottom: 4px;
    }

    &--bg {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, #000000 100%);
      display: flex;
      justify-content: center;
    }
  }
}
.border-bot{
  border-bottom: 4px solid #BF0019;
}

@media screen and (max-width: 576px) {
  .header {
    &__content {
      h1 {
        font-size: 30px;
        line-height: 52px;
      }

      &--text {
        font-size: 13px;
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1420px) {
  .home__container {
    padding-left: 70px;
    padding-right: 70px;
  }

  .trangtracuucovat {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .home__container {
    padding-left: 70px;
    padding-right: 70px;
  }

  .trangtracuucovat {
    padding-left: 70px;
    padding-right: 70px;
  }
}