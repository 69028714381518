.listitem {
  &__h1 {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
    color: #FFFFFF;
    text-transform: uppercase;
  }

  &__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    text-transform: uppercase;
    color: #060606;
  }

  &__search {
    border-bottom: 1px solid#FFFFFF;
    max-width: 310px;
    width: 100%;

    input {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
      color:#FFFFFF;
    }
    input::-webkit-input-placeholder { /* Edge */
      color: #fff;
    }
  }

  &__product {
    img {
      width: 100%;
      margin-bottom: 20px;
    }

    h3 {
      font-family: 'Lora';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 31px;
      color: #060606;
      margin-bottom: 14px;
    }

    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #060606;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    background-color: #F15922;
    color: #fff;
    z-index: 9999 !important;
    border-radius: 50%;
    height: 47px;
    width: 47px;
    margin-right: 0;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    background-color: #F15922;
    color: #fff;
    z-index: 9999 !important;
    border-radius: 50%;
    height: 47px;
    width: 47px;
    margin-left: 0;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 15px;
    font-weight: 600;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: -60px;
    right: auto;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: -60px;
    left: auto;
  }

  .swiper {
    overflow: unset;
  }

  .swiper-container {
    width: 480px;
  }

  @media screen and (min-width: 320px) {


    &__item-top {
      display: flex;
    }
  }

  @media screen and (max-width: 767px) {
    .swiper {
      overflow: hidden;
    }
  }

  @media screen and (max-width: 576px) {
    &__item-top {
      display: flex;
      flex-flow: column-reverse;
    }
  }

  @media screen and (min-width: 1200px) {
    .swiper-container {
      width: 800px;
    }
  }
}

@media screen and (max-width: 576px) {
  .listitem {
    padding-left: 20px;
    padding-right: 20px;

    &__product div {
      height: 100px;
    }

    &__product h3 {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &__product p {
      font-size: 13px;
    }

    &__title {
      font-size: 16px;
    }

    &__search {
      max-width: 100%;
    }
  }

  .home {
    &__title {
      padding-right: 20px;
      padding-left: 20px;
      flex-flow: column;
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .listitem {
    padding-left: 20px;

    &__h1 {
      padding-left: 20px;
    }

    &__product div {
      height: 140px;
      background-position: center;
    }

    &__product h3 {
      font-size: 18px;
      margin-bottom: 8px;
    }

    &__product p {
      font-size: 14px;
    }

    &__title {
      font-size: 18px;
    }
  }
}

@media (max-width: 400px) {
  .listitem__product div {
    height: 70px;
  }
}